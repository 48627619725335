import { Base64 } from 'js-base64';
import md5 from 'md5';
import ReactGA from 'react-ga';
import * as types from '../constants/ActionTypes';

const grecaptcha = window.grecaptcha;

let fetchTimeOut;

function validateZipcode(zipcode) {
  var regex = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
  return regex.test(zipcode);
}

export const selectQuery = query => ({
  type: types.SELECT_QUERY,
  query
});

export const setSettings = settings => ({
  type: types.SET_SETTINGS,
  settings
});

export const updateSettings = settings => ({
  type: types.UPDATE_SETTINGS,
  settings
});

export const invalidateQuery = (query, settingsMd5) => ({
  type: types.INVALIDATE_QUERY,
  query,
  settingsMd5
});

export const requestCompanies = (query, settingsMd5) => ({
  type: types.REQUEST_COMPANIES,
  query,
  settingsMd5
});

export const receiveCompanies = (query, settingsMd5, json) => ({
  type: types.RECEIVE_COMPANIES,
  query,
  settingsMd5,
  companies: json.companies,
  allowDownload: json.allowDownload,
  numFound: json.numFound,
  receivedAt: Date.now()
});

const fetchCompanies = (query, settings) => dispatch => {
  let settingsMd5 = md5(JSON.stringify(settings));
  let settingsBase64 = Base64.encode(JSON.stringify(settings));

  if (fetchTimeOut) {
    clearTimeout(fetchTimeOut);
  }

  fetchTimeOut = setTimeout(function() {
    grecaptcha.ready(function() {
      grecaptcha
        .execute('6LcV3XcUAAAAABcSBS8ewWynQ4cbK6BgXvWq7erC', {
          action: 'search'
        })
        .then(function(token) {
          // Verify the token on the server.
          dispatch(requestCompanies(query, settingsMd5));

          ReactGA.pageview(`/search?q=${query ? query : '*'}`);

          return fetch(
            `https://3z2kdqchj5.execute-api.eu-west-1.amazonaws.com/dev/lead-generator?q=${
              query ? query : '*'
            }&grecaptchaToken=${token}&settings=${settingsBase64}`,
            {
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'Tkf8rl1LgI1jiQMCYpsdb960GuI6ydHDap6tI9hN'
              }
            }
          )
            .then(response => response.json())
            .then(json => dispatch(receiveCompanies(query, settingsMd5, json)));
        });
    });
  }, 500);
};

const shouldFetchCompanies = (state, query, settings) => {
  let settingsMd5 = md5(JSON.stringify(settings));

  const companies = state.companiesByQuery[query + settingsMd5];
  if (!companies) {
    return true;
  }
  if (companies.isFetching) {
    return false;
  }
  return companies.didInvalidate;
};

export const fetchCompaniesIfNeeded = query => (dispatch, getState) => {
  if (shouldFetchCompanies(getState(), query, getState().settings)) {
    return dispatch(fetchCompanies(query, getState().settings));
  }
};

export const selectLocationQuery = query => ({
  type: types.SELECT_LOCATION_QUERY,
  query
});

export const invalidateLocationQuery = query => ({
  type: types.INVALIDATE_LOCATION_QUERY,
  query
});

export const requestLocation = query => ({
  type: types.REQUEST_LOCATION,
  query
});

export const receiveLocation = (query, json) => ({
  type: types.RECEIVE_LOCATION,
  query,
  json: json,
  receivedAt: Date.now()
});

const fetchLocation = query => dispatch => {
  dispatch(requestLocation(query));

  let locationQuery = query;

  if (validateZipcode(query) === true) {
    locationQuery = query.replace(/\D/g, '');
  }

  return fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${locationQuery}.json?access_token=pk.eyJ1IjoiaW5kZW50eXRvb2xpbmciLCJhIjoiY2puZDhrY3h1MDJrZTN2cXBoam5ta201NSJ9.52dIZCJ64CF4poIJw5sWlw&types=place,region,postcode&country=NL&language=nl&fuzzyMatch=false`
  )
    .then(response => response.json())
    .then(json => dispatch(receiveLocation(query, json)));
};

const shouldFetchLocation = (state, query) => {
  const location = state.locationByQuery[query];
  if (!location) {
    return true;
  }
  if (location.isFetching) {
    return false;
  }
  return location.didInvalidate;
};

export const fetchLocationIfNeeded = query => (dispatch, getState) => {
  if (shouldFetchLocation(getState(), query)) {
    return dispatch(fetchLocation(query));
  }
};
