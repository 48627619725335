import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import apps from '../data/apps.json';

let appsObject = apps.apps;
let lowerCaseApps = {};
for (var property in appsObject) {
  if (appsObject.hasOwnProperty(property)) {
    let app = appsObject[property];
    app.orginalName = property;
    lowerCaseApps[
      property
        .toLowerCase()
        .split(' ')
        .join('')
    ] = app;
  }
}
function WebsiteApps({ apps }) {
  return (
    <div className="horizontal layout flex wrap ">
      {apps.map(app => (
        <Chip
          key={app}
          avatar={
            <Avatar
              style={{
                backgroundColor: '#fff',
                border: '0.5px solid #e6e6e6'
              }}
              src={
                lowerCaseApps[app.split(' ').join('')]
                  ? `/icons/${lowerCaseApps[app.split(' ').join('')].icon}`
                  : ''
              }
            />
          }
          label={
            lowerCaseApps[app.split(' ').join('')]
              ? lowerCaseApps[app.split(' ').join('')].orginalName
              : app
          }
          style={{ margin: '0.4em' }}
        />
      ))}
    </div>
  );
}

export default WebsiteApps;
