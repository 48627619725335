import axios from 'axios';
import config from '../config/settings';

async function fetchResellerTheme(subdomain) {
  const response = await axios.get(
    `${config.RAPPORTAGE_MARKETING_API_BASE_URL}/report/reseller-theme?resellerId=${subdomain}`,
    {
      headers: {
        'x-api-key': config.RAPPORTAGE_MARKETING_API_API_KEY
      }
    }
  );
  if (response && response.data) {
    return response.data;
  }
}

export default fetchResellerTheme;
