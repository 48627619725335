export const SET_SETTINGS = 'SET_SETTINGS';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

export const REQUEST_COMPANIES = 'REQUEST_COMPANIES';
export const RECEIVE_COMPANIES = 'RECEIVE_COMPANIES';
export const SELECT_QUERY = 'SELECT_QUERY';
export const INVALIDATE_QUERY = 'INVALIDATE_QUERY';

export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION';
export const SELECT_LOCATION_QUERY = 'SELECT_LOCATION_QUERY';
export const INVALIDATE_LOCATION_QUERY = 'INVALIDATE_LOCATION_QUERY';

export const REQUEST_RESELLER_THEME = 'REQUEST_RESELLER_THEME';
export const RECEIVE_RESELLER_THEME = 'RECEIVE_RESELLER_THEME';
export const INVALIDATE_RESELLER_THEME = 'INVALIDATE_RESELLER_THEME';
