import { useTheme } from '@material-ui/core/styles';
import React from 'react';

function StylesAppContainer() {
  const theme = useTheme();

  let { palette } = theme;
  let { primary, secondary } = palette;
  return (
    <>
      <style>
        {`
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 4px;
  border-radius: 6px;
  background-color: ${secondary.main};
}
.rc-slider-handle {
  position: absolute;
  margin-left: -7px;
  margin-top: -5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  cursor: grab;
  border-radius: 50%;
  border: solid 2px ${primary.main};
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle:focus {
  border-color: ${secondary.main};
  box-shadow: 0 0 0 5px ${primary.main};
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: ${secondary.main};
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: ${secondary.main};
}
.rc-slider-handle:active {
  border-color: ${secondary.main};
  box-shadow: 0 0 5px ${secondary.main};
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.rc-slider-dot-active {
  border-color: ${primary.main};
}

`}
      </style>
    </>
  );
}

export default StylesAppContainer;
