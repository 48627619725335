import TextField from '@material-ui/core/TextField';
import React from 'react';

function TextFieldWithSuggestions(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

export default TextFieldWithSuggestions;
