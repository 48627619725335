function shouldFetchLocation(query, locationByQuery) {
  const location = locationByQuery[query];
  if (!location) {
    return true;
  }
  if (location.isFetching) {
    return false;
  }
  return location.didInvalidate;
}

export default shouldFetchLocation;
