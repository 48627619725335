import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GpsFixedOutlined from '@material-ui/icons/GpsFixedOutlined';
import GpsNotFixedOutlined from '@material-ui/icons/GpsNotFixedOutlined';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import MapGL, { Marker } from 'react-map-gl';
import { connect, useDispatch, useSelector } from 'react-redux';
import Circle from '../components/Circle';
import CityPin from '../components/CityPin';
import SelectMultipleChip from '../components/SelectMultipleChip';
import config from '../config/settings';
import * as types from '../constants/ActionTypes';
import appSettings from '../data/settings.json';
import calculateViewport from '../utilities/calculateViewport';
import fetchLocation from '../utilities/fetchLocation';
import calculateSize from '../utilities/mapCalculateSizeCircle';
import shouldFetchLocation from '../utilities/shouldFetchLocation';

const Handle = Slider.Handle;

const defaultViewport = config.MAP_DEFAULT_VIEW_PORT;
const defaultDialogViewport = config.MAP_DEFAULT_VIEW_PORT_DIALOG;
const purposeOfUseLocations = config.PURPOSE_OF_USE_LOCATIONS;

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

function LocationFilterSettingsContainer() {
  const theme = useTheme();

  const dispatch = useDispatch();
  const locationByQuery = useSelector(state => state.locationByQuery);
  const selectedLocationQuery = useSelector(
    state => state.selectedLocationQuery
  );
  const settings = useSelector(state => state.settings);

  const { items: foundLocation } = locationByQuery[selectedLocationQuery] || {
    items: {}
  };

  const [targetLocationRadius, setTargetLocationRadius] = useState(0);
  const [viewport, setViewport] = useState(defaultDialogViewport);
  const [locationOpen, setLocationOpen] = useState(false);
  const [didYouMean, setDidYouMean] = useState('');
  const [dialogViewport, setDialogViewport] = useState(defaultDialogViewport);
  const [foundFeature, setFoundFeature] = useState(undefined);
  const [usedFeature, setUsedFeature] = useState(0);

  useEffect(() => {
    if (settings && settings.location) {
      const firstLocation = settings.location[0];
      const bboxArray = settings.location.map(l => l.bbox);
      const nextViewport =
        bboxArray.length > 0
          ? calculateViewport(
              bboxArray,
              viewport.width,
              viewport.height,
              firstLocation.center,
              targetLocationRadius
            )
          : defaultViewport;
      setViewport(nextViewport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (foundLocation && foundLocation.features) {
      let foundLocations = foundLocation;

      if (foundLocations.features && foundLocations.features.length > 0) {
        let firstFeature = foundLocations.features[0];

        let secondFeature;
        if (foundLocations.features.length > 1) {
          secondFeature = foundLocations.features[1];
        }

        const dialogViewport =
          foundLocations && foundLocations.features
            ? calculateViewport(
                firstFeature.bbox,
                370,
                200,
                firstFeature.center,
                targetLocationRadius
              )
            : defaultDialogViewport;

        setUsedFeature(0);
        setDialogViewport(dialogViewport);
        setFoundFeature(foundLocation.features[0]);
        setDidYouMean(secondFeature ? secondFeature.place_name : '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLocation]);

  let { location: locationSettings, bag_gebruiksdoel } = settings;

  let bboxArray = [];
  let alreadyHasTargetLocationRadius = false;

  for (let i = 0; i < locationSettings.length; i++) {
    let locationItem = locationSettings[i];
    let { bbox, targetLocationRadius } = locationItem;
    bboxArray.push(bbox);

    if (targetLocationRadius > 0) {
      alreadyHasTargetLocationRadius = true;
    }
  }

  if (locationOpen === true) {
    ReactGA.modalview('/location-filter-modal');
  }

  function renderCircleMarker(city, zoom, radius) {
    return (
      <Marker
        key={`circle`}
        longitude={city.center[0]}
        latitude={city.center[1]}
      >
        <Circle size={calculateSize(radius, zoom, city.center[1])} />
      </Marker>
    );
  }

  function renderCityMarker(city, index) {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={city.center[0]}
        latitude={city.center[1]}
      >
        <CityPin size={20} />
      </Marker>
    );
  }

  function onSliderChange(value) {
    const dialogViewport =
      foundLocation && foundLocation.features
        ? calculateViewport(
            foundFeature.bbox,
            370,
            200,
            foundFeature.center,
            targetLocationRadius
          )
        : defaultDialogViewport;

    setDialogViewport(dialogViewport);
    setTargetLocationRadius(value);
  }

  function handleDelete(data) {
    if (foundLocation && foundLocation.features) {
      const chipData = [...settings.location];
      const chipToDelete = chipData.indexOf(data);
      chipData.splice(chipToDelete, 1);

      let newSettings = {
        ...settings,
        location: chipData
      };
      dispatch({
        type: types.UPDATE_SETTINGS,
        settings: newSettings
      });
    }
  }

  function handleAddFilterLocation() {
    if (foundLocation && foundLocation.features) {
      let firstFeature = foundLocation.features[usedFeature];

      let originalLocationSettings = [...settings.location];

      if (targetLocationRadius > 0) {
        originalLocationSettings = [];
      }

      let newSettings = {
        ...settings,
        location: [
          ...originalLocationSettings,
          {
            ...firstFeature,
            selectedLocationQuery: selectedLocationQuery,
            targetLocationRadius: targetLocationRadius
          }
        ]
      };

      dispatch({
        type: types.UPDATE_SETTINGS,
        settings: newSettings
      });

      setLocationOpen(false);
    }
  }

  async function fetchLocationObject() {
    if (shouldFetchLocation(selectedLocationQuery, locationByQuery)) {
      dispatch({
        type: types.REQUEST_LOCATION,
        query: selectedLocationQuery
      });

      let json = await fetchLocation(selectedLocationQuery);

      dispatch({
        type: types.RECEIVE_LOCATION,
        query: selectedLocationQuery,
        json: json,
        receivedAt: Date.now()
      });
    }
  }

  return (
    <>
      <ListSubheader component="div">Locatie</ListSubheader>
      <Dialog
        open={locationOpen}
        onClose={() => {
          setLocationOpen(false);
        }}
      >
        <DialogTitle>Filter op locatie</DialogTitle>
        <DialogContent>
          <div className="layout flex vertical">
            <MapGL
              {...dialogViewport}
              mapOptions={{
                attributionControl: false
              }}
              mapboxApiAccessToken={appSettings.mapbox.mapboxApiAccessToken}
              mapStyle={
                theme.palette.type === 'light'
                  ? appSettings.mapbox.mapStyle
                  : appSettings.mapbox.mapStyleDark
              }
            >
              {foundLocation &&
              foundLocation.features &&
              foundLocation.features[usedFeature]
                ? renderCircleMarker(
                    foundLocation.features[usedFeature],
                    dialogViewport.zoom,
                    targetLocationRadius * 1000
                  )
                : ''}

              {foundLocation &&
              foundLocation.features &&
              foundLocation.features[usedFeature]
                ? renderCityMarker(foundLocation.features[usedFeature], 0)
                : ''}
            </MapGL>

            {foundLocation && foundLocation.features ? (
              <AppBar
                style={{ boxShadow: 'none' }}
                position="static"
                color="default"
              >
                <Toolbar variant="dense">
                  <Typography variant="subtitle1" align="center">
                    {foundLocation &&
                    foundLocation.features &&
                    foundLocation.features[usedFeature]
                      ? foundLocation.features[usedFeature].place_name
                      : ''}
                  </Typography>
                </Toolbar>
              </AppBar>
            ) : (
              ''
            )}
            <div style={{ padding: '16px 0' }} />

            {foundLocation && foundLocation.features ? (
              <ExpansionPanel disabled={alreadyHasTargetLocationRadius}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <div className="layout flex vertical">
                    <Typography>Targetlocatie radius rondom locatie</Typography>
                    {alreadyHasTargetLocationRadius === true ? (
                      <Typography variant="caption">
                        Maximaal 1 filter met radius mogelijk
                      </Typography>
                    ) : null}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div className="layout flex vertical">
                    <Typography
                      gutterBottom
                      color="textSecondary"
                      style={{
                        fontSize: '11px'
                      }}
                    >
                      Targetlocatie radius rondom locatie
                    </Typography>

                    <Slider
                      min={0}
                      max={80}
                      onChange={value => {
                        onSliderChange(value);
                      }}
                      defaultValue={targetLocationRadius}
                      handle={handle}
                    />
                    <Typography gutterBottom variant="caption">
                      {targetLocationRadius.toLocaleString('nl', {
                        maximumFractionDigits: 0
                      })}{' '}
                      KM
                    </Typography>

                    {targetLocationRadius > 0 ? (
                      <Typography
                        gutterBottom
                        variant="caption"
                        align="center"
                        style={{
                          color:
                            theme.palette.type === 'light' ? red[700] : red[200]
                        }}
                      >
                        Let op! <br />
                        Het is alleen mogelijk om te filter op 1 locatie
                      </Typography>
                    ) : null}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null}

            {didYouMean ? (
              <Typography style={{ marginTop: '20px' }} gutterBottom>
                Bedoelde je:{' '}
                <span
                  onClick={() => {
                    let secondFeature = foundLocation.features[1];

                    const dialogViewport =
                      foundLocation && foundLocation.features
                        ? calculateViewport(
                            secondFeature.bbox,
                            370,
                            200,
                            secondFeature.center,
                            targetLocationRadius
                          )
                        : defaultDialogViewport;

                    setUsedFeature(1);
                    setDialogViewport(dialogViewport);
                    setFoundFeature(secondFeature);
                    setDidYouMean('');
                  }}
                  style={{ color: '#3ea0d1', cursor: 'pointer' }}
                >
                  {didYouMean}
                </span>{' '}
                ?
              </Typography>
            ) : null}

            <TextField
              style={{ marginTop: '20px' }}
              id="standard-search"
              label="Zoek op locatie"
              type="search"
              value={selectedLocationQuery}
              onKeyPress={e => {
                if (e.key === undefined || e.key === 'Enter') {
                  fetchLocationObject();
                }
              }}
              onChange={event => {
                dispatch({
                  type: types.SELECT_LOCATION_QUERY,
                  query: event.target.value
                });
              }}
              margin="none"
              fullWidth
            />

            {foundLocation && foundLocation.features ? (
              <Button
                disabled={!(foundLocation && foundLocation.features)}
                style={{ marginTop: '40px' }}
                onClick={() => {
                  handleAddFilterLocation();
                }}
                variant="contained"
                color="primary"
              >
                Voeg toe aan filterlijst
              </Button>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>

      <div style={{ padding: '0px 16px 16px 16px' }}>
        {locationSettings.length > 0 ? (
          <div
            style={{ marginBottom: '20px' }}
            className="layout flex horizontal wrap"
          >
            {locationSettings.map(data => {
              let icon = null;

              if (data.targetLocationRadius > 0) {
                icon = <GpsNotFixedOutlined />;
              } else {
                icon = <GpsFixedOutlined />;
              }

              return (
                <Chip
                  key={data.id}
                  icon={icon}
                  style={{ margin: '2px 5px' }}
                  onDelete={() => {
                    handleDelete(data);
                  }}
                  label={
                    data.id.startsWith('postcode')
                      ? data.selectedLocationQuery
                      : data.text
                  }
                />
              );
            })}
          </div>
        ) : null}

        <MapGL
          {...viewport}
          mapboxApiAccessToken={appSettings.mapbox.mapboxApiAccessToken}
          mapOptions={{
            attributionControl: false
          }}
          mapStyle={
            theme.palette.type === 'light'
              ? appSettings.mapbox.mapStyle
              : appSettings.mapbox.mapStyleDark
          }
        >
          {locationSettings.length === 1 &&
          locationSettings[0].targetLocationRadius > 0
            ? renderCircleMarker(
                locationSettings[0],
                viewport.zoom,
                locationSettings[0].targetLocationRadius * 1000
              )
            : null}
          {locationSettings.map(renderCityMarker)}
        </MapGL>

        <Button
          style={{ width: '100%', marginTop: '10px' }}
          variant="outlined"
          color={'primary'}
          disabled={alreadyHasTargetLocationRadius}
          onClick={() => {
            setLocationOpen(true);
            setTargetLocationRadius(0);
          }}
        >
          Filter op locatie
        </Button>
      </div>

      <SelectMultipleChip
        value={bag_gebruiksdoel}
        label={'Gebruiksdoel'}
        inputArray={purposeOfUseLocations}
        handleSettingsChange={event => {
          let value = event.target.value;

          let newSettings = {
            ...settings,
            bag_gebruiksdoel: value
          };

          dispatch({
            type: types.UPDATE_SETTINGS,
            settings: newSettings
          });
        }}
      />
    </>
  );
}

const mapStateToProps = state => {
  const { locationByQuery, selectedLocationQuery, settings } = state;

  const { items: location } = locationByQuery[selectedLocationQuery] || {
    items: {}
  };

  return {
    selectedLocationQuery,
    location,
    settings
  };
};

export default connect(mapStateToProps)(LocationFilterSettingsContainer);
