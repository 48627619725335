import config from '../config/settings';

const meterPerPixel = config.MAP_METER_PER_PIXEL;
const scaleFactor = meterPerPixel * config.MAP_SCALE_FACTOR_MULTIPLIER;

const calculateSize = (radius, zoom, lat) => {
  const size = (2 * radius) / scaleFactor / Math.cos((lat * Math.PI) / 180);
  return size / 2 ** (17 - zoom);
};

export default calculateSize;
