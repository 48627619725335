import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import Slider from 'rc-slider';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SBIAutocomplete from '../components/SBIAutocomplete';
import SelectMultipleChip from '../components/SelectMultipleChip';
import settings from '../config/settings';
import * as types from '../constants/ActionTypes';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const legalForms = settings.LEGAL_FORMS;
const typeCompany = settings.TYPE_COMPANIES;

function CompanyFilterSettingsContainer() {
  const dispatch = useDispatch();

  const settings = useSelector(state => state.settings);

  let { orgtype, type, employees, sbi } = settings;

  const [employeesLabel, setEmployeesLabel] = useState(
    settings.employees ? settings.employees : [0, 0]
  );

  function handleEmployeesChange(event, name) {
    let value;

    if (event.target && event.target.checked !== undefined) {
      value = event.target.checked;
    } else if (Array.isArray(event) && event.length === 2) {
      value = event;
    } else {
      value = event.target.value;
    }

    if (name === 'employees') {
      setEmployeesLabel(value);
    }
  }

  function handleSettingsChange(event, name) {
    let value;

    if (event.target && event.target.checked !== undefined) {
      value = event.target.checked;
    } else if (Array.isArray(event) && event.length === 2) {
      value = event;
    } else {
      value = event.target.value;
    }

    let newSettings = {
      ...settings,
      [name]: value
    };

    if (name === 'employees') {
      setEmployeesLabel(value);
    }

    dispatch({
      type: types.UPDATE_SETTINGS,
      settings: newSettings
    });
  }

  return (
    <>
      <ListSubheader component="div">Bedrijf</ListSubheader>

      <SBIAutocomplete sbi={sbi} />

      <SelectMultipleChip
        value={type}
        label={'Type vestiging'}
        inputArray={typeCompany}
        handleSettingsChange={event => {
          handleSettingsChange(event, 'type');
        }}
      />

      <SelectMultipleChip
        value={orgtype}
        label={'Rechtsvorm'}
        inputArray={legalForms}
        handleSettingsChange={event => {
          handleSettingsChange(event, 'orgtype');
        }}
      />

      <div style={{ padding: '0px 16px 16px 16px' }}>
        <Typography
          gutterBottom
          color="textSecondary"
          style={{ fontSize: '11px' }}
        >
          Bedrijfsgrootte
        </Typography>

        <div style={{ padding: '6px 0px' }}>
          <Range
            id="range-slider"
            min={0}
            max={2000}
            step={10}
            defaultValue={employees}
            onChange={event => {
              handleEmployeesChange(event, 'employees');
            }}
            onAfterChange={event => {
              handleSettingsChange(event, 'employees');
            }}
            tipFormatter={value => `${value}%`}
          />
        </div>
        <Typography variant="caption">
          {employeesLabel[0].toLocaleString('nl', {
            maximumFractionDigits: 0
          })}{' '}
          -{' '}
          {employeesLabel[1] === 2000
            ? '5,001+'
            : employeesLabel[1].toLocaleString('nl', {
                maximumFractionDigits: 0
              })}{' '}
          medewerkers
        </Typography>
      </div>
    </>
  );
}

export default CompanyFilterSettingsContainer;
