let settings = {
  GOOGLE_ANALYTICS_UA_CODE: 'UA-33792686-7',
  RAPPORTAGE_MARKETING_API_BASE_URL: 'https://api.rapportage.marketing',
  RAPPORTAGE_MARKETING_API_API_KEY: 'DecLvYvuHjbNCwiuQR0n225npicDXiP2uXxXgPVg',
  RECAPTCHA_TOKEN: '6LcV3XcUAAAAABcSBS8ewWynQ4cbK6BgXvWq7erC',
  RECAPTCHA_ACTION: 'search',
  LEAD_GENERATOR_API_BASE_URL:
    'https://3z2kdqchj5.execute-api.eu-west-1.amazonaws.com',
  LEAD_GENERATOR_API_KEY: 'Tkf8rl1LgI1jiQMCYpsdb960GuI6ydHDap6tI9hN',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1IjoiaW5kZW50eXRvb2xpbmciLCJhIjoiY2puZDhrY3h1MDJrZTN2cXBoam5ta201NSJ9.52dIZCJ64CF4poIJw5sWlw',
  BASE_THEME_LIGHT_PRIMARY_COLOR: '#455A64',
  BASE_THEME_LIGHT_SECONDARY_COLOR: '#F57C00',
  BASE_THEME_DARK_PRIMARY_COLOR: '#B0BEC5',
  BASE_THEME_DARK_SECONDARY_COLOR: '#FFCC80',
  MAP_DEFAULT_VIEW_PORT: {
    longitude: 5.400489,
    latitude: 52.18258,
    zoom: 5.5,
    bearing: 0,
    pitch: 0,
    width: 290,
    height: 300
  },
  MAP_DEFAULT_VIEW_PORT_DIALOG: {
    longitude: 5.600489,
    latitude: 52.21158,
    zoom: 6,
    bearing: 0,
    pitch: 0,
    width: 370,
    height: 200
  },
  MAP_METER_PER_PIXEL: 0.3657133691291681,
  MAP_SCALE_FACTOR_MULTIPLIER: 1.6299644013743293,
  SETTINGS_DEFAULT_TYPE: ['Hoofdvestiging', 'Rechtspersoon'],
  SETTINGS_DEFAULT_BAG_GEBRUIKSDOEL: [
    'kantoorfunctie',
    'winkelfunctie',
    'overige',
    'industriefunctie'
  ],
  SETTINGS_DEFAULT_EMPLOYEES: [0, 2000],
  LEGAL_FORMS: [
    'BV',
    'Eenmanszaak',
    'Stichting',
    'VOF',
    'Vereniging',
    'Beheer BV',
    'Maatschap',
    'NV',
    'Coperatie',
    'CV',
    'Religieuze organisaties',
    'Gemeente',
    'Cooperatie',
    'Stamrecht vennootschap',
    'Rederij'
  ],
  PURPOSE_OF_USE_LOCATIONS: [
    'bijeenkomstfunctie',
    'woonfunctie',
    'celfunctie',
    'kantoorfunctie',
    'winkelfunctie',
    'gebruiksfunctie',
    'overige',
    'industriefunctie',
    'sportfunctie',
    'logiesfunctie',
    'onderwijsfunctie',
    'gezondheidszorgfunctie'
  ],
  TYPE_COMPANIES: ['Hoofdvestiging', 'Rechtspersoon', 'Nevenvestiging']
};

export default settings;
