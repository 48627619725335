function getSubdomain() {
  const full = window.location.host;
  const parts = full.split('.');

  const sub = parts.length === 3 ? parts[0] : undefined;

  if (full.indexOf('cloudfront') > -1 || sub === 'beta') {
    return undefined;
  } else {
    return sub;
  }
}

export default getSubdomain;
