import md5 from 'md5';

function shouldFetchCompanies(companiesByQuery, selectedQuery, settings) {
  let settingsMd5 = md5(JSON.stringify(settings));

  const companies = companiesByQuery[selectedQuery + settingsMd5];
  if (!companies) {
    return true;
  }
  if (companies.isFetching) {
    return false;
  }
  return companies.didInvalidate;
}

export default shouldFetchCompanies;
