import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

function ShareLinkDialog({
  shareLink,
  openShareDialog,
  handleShareClose,
  onCopy
}) {
  return (
    <Dialog
      open={openShareDialog}
      onClose={handleShareClose}
      aria-labelledby="share-dialog-title"
      aria-describedby="share-dialog-description"
    >
      <DialogTitle>Mail onderstaande link</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center" flexDirection="row">
          <input
            style={{
              marginBottom: '0px',
              padding: '.5em'
            }}
            value={shareLink}
            readOnly
          />
          &nbsp;
          <CopyToClipboard text={shareLink} onCopy={onCopy}>
            <Button
              style={{ marginLeft: '10px' }}
              variant="contained"
              color="primary"
            >
              Kopieer naar klembord
            </Button>
          </CopyToClipboard>
          &nbsp;
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ShareLinkDialog;
