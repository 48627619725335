import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Downshift from 'downshift';
import fuzzaldrin from 'fuzzaldrin-plus';
import keycode from 'keycode';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { updateSettings } from '../actions';
import Suggestion from '../components/Suggestion';
import TextFieldWithSuggestions from '../components/TextFieldWithSuggestions';
import suggestionsObjects from '../data/sbi.json';

let suggestions = [];

for (let i = 0; i < suggestionsObjects.length; i++) {
  let suggestion = suggestionsObjects[i];

  // Use SBI format, used in their PDF example (01.23.4)
  let sbiString = `${String(suggestion.SBI).length === 3 ? '0' : ''}${
    suggestion.SBI
  }`;
  let sbiStringPretty = '';
  let tempCounter = 0;
  for (let i = 0; i < sbiString.length; i++) {
    let sbiStringChar = sbiString[i];
    sbiStringPretty += sbiStringChar;
    tempCounter++;
    if (tempCounter === 2 && i + 1 < sbiString.length) {
      sbiStringPretty += '.';
      tempCounter = 0;
    }
  }

  suggestions.push(`${suggestion.Omschrijving} (${sbiStringPretty})`);
}

const fuzzyFilter = input => {
  return fuzzaldrin.filter(suggestions, input);
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0 ? [] : fuzzyFilter(inputValue);
}

class DownshiftMultiple extends React.Component {
  state = {
    inputValue: '',
    selectedItem: this.props.sbi
  };

  handleKeyDown = event => {
    const { inputValue } = this.state;
    const { dispatch, settings } = this.props;

    let selectedItem = [...this.props.sbi];

    if (
      selectedItem.length &&
      !inputValue.length &&
      keycode(event) === 'backspace'
    ) {
      let newSettings = {
        ...settings,
        sbi: selectedItem.slice(0, selectedItem.length - 1)
      };

      this.setState({
        inputValue: ''
      });

      dispatch(updateSettings(newSettings));
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = item => {
    const { dispatch, settings } = this.props;

    let selectedItem = this.props.sbi;

    if (this.props.sbi.indexOf(item) === -1) {
      selectedItem = [...this.props.sbi, item];
    }

    let newSettings = {
      ...settings,
      sbi: selectedItem
    };

    this.setState({
      inputValue: ''
    });

    dispatch(updateSettings(newSettings));
  };

  handleDelete = item => () => {
    const { dispatch, settings } = this.props;
    let selectedItem = [...this.props.sbi];

    selectedItem.splice(selectedItem.indexOf(item), 1);

    let newSettings = {
      ...settings,
      sbi: selectedItem
    };

    this.setState({
      inputValue: ''
    });

    dispatch(updateSettings(newSettings));
  };

  render() {
    const { classes } = this.props;
    const { inputValue } = this.state;

    let selectedItem = this.props.sbi;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            <TextFieldWithSuggestions
              {...{
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  startAdornment: selectedItem.map(item => (
                    <Chip
                      key={item}
                      tabIndex={-1}
                      label={item}
                      className={classes.chip}
                      onDelete={this.handleDelete(item)}
                    />
                  )),
                  onChange: this.handleInputChange,
                  onKeyDown: this.handleKeyDown,
                  placeholder: 'Filter op een of meerdere SBI-codes'
                }),

                label: 'Standaard Bedrijfsindeling (SBI-codes)'
              }}
            />

            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue2)
                  .slice(0, 5)
                  .map((suggestion, index) => (
                    <Suggestion
                      key={suggestion}
                      suggestion={suggestion}
                      index={index}
                      itemProps={getItemProps({ item: suggestion })}
                      highlightedIndex={highlightedIndex}
                      selectedItem={selectedItem2}
                    />
                  ))}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  },
  divider: {
    height: theme.spacing(2)
  }
});

function IntegrationDownshift(props) {
  const { classes } = props;

  return (
    <div style={{ padding: '0px 16px 16px 16px' }}>
      <DownshiftMultiple classes={classes} {...props} />
    </div>
  );
}

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(
  withStyles(styles)(IntegrationDownshift)
);
