import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import translate from '../data/translate.json';

const drawerWidth = 360;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },

  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3)
  },
  socialIcons: {
    marginRight: '10px'
  },
  socialButton: {
    marginRight: '10px'
  },

  expansionPanelSummary: {
    paddingLeft: '0px',
    paddingRight: '0px'
  },

  companyLink: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased !important',
    MozFontSmoothing: 'antialiased !important',
    textRendering: 'optimizelegibility !important',
    letterSpacing: '.03em'
  },

  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: 'auto'
  },

  avatar: {
    background: theme.palette.primary.main
  },

  appBarWhite: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#fff'
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    overflow: 'auto',
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },

  toolbar: theme.mixins.toolbar,

  margin: {
    margin: theme.spacing(1)
  },

  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  bootstrapFormLabel: {
    fontSize: 18
  }
}));

function CompanyTable({ companies, handleClickOpen }) {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['name'] ? translate['name'].name : ''}
          </TableCell>
          <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['orgtype'] ? translate['orgtype'].name : ''}
          </TableCell>
          <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['url'] ? translate['url'].name : ''}{' '}
          </TableCell>
          <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['categoryshort'] ? translate['categoryshort'].name : ''}
          </TableCell>
          <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['city'] ? translate['city'].name : ''}
          </TableCell>

          <TableCell style={{ textTransform: 'capitalize' }}>
            {translate['provincie'] ? translate['provincie'].name : ''}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {companies.slice(0, 15).map(company => {
          return (
            <TableRow key={company.id}>
              <TableCell
                onClick={() => {
                  handleClickOpen(company.id);
                }}
                className={classes.companyLink}
              >
                {company['name'] ? company['name'] : '-'}
              </TableCell>
              <TableCell>
                {company['orgtype'] ? company['orgtype'] : '-'}
              </TableCell>
              <TableCell>{company['url'] ? company['url'][0] : '-'} </TableCell>
              <TableCell>
                {company['categoryshort'] ? company['categoryshort'] : '-'}
              </TableCell>
              <TableCell>{company['city'] ? company['city'] : '-'}</TableCell>
              <TableCell>
                {company['provincie'] ? company['provincie'] : '-'}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default CompanyTable;
