import 'babel-polyfill';
import React from 'react';
import 'react-app-polyfill/ie9';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import 'typeface-roboto';
import Root from './containers/Root';
import './index.css';
import configureStore from './store/configureStore';

const store = configureStore();
render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('root')
);
