import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MobileStepper from '@material-ui/core/MobileStepper';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import SwipeableViews from 'react-swipeable-views';
import getSubdomain from '../utilities/getSubdomain';

const tutorialSteps = [
  {
    label: 'Stel zelf uw ideale doelgroep samen via onze unieke tool.'
  },
  {
    label:
      'Vind uw nieuwe leads door te zoeken in onze bedrijvendatabase met meer dan 2,8 miljoen Nederlandse bedrijven.'
  },
  {
    label:
      "Om misbruik te voorkomen verstrekken wij geen contactgegevens van freelancers en zzp'ers."
  },
  {
    label:
      'Let op! Voor deze tool heb je een accountmanager nodig. Geen accountmanager? Neem contact met ons op.'
  },

  {
    label: 'Vragen? Feedback? Neem contact met ons op.'
  }
];

function OnboardingScreen({ handleCloseOnBoarding, onboardingActive }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(true);

  const [activeStep, setActiveStep] = useState(0);

  let { palette } = theme;
  let { secondary: secondaryBrandColor } = palette;

  const maxSteps = tutorialSteps.length;

  let mail = getSubdomain() ? getSubdomain() : 'info';

  function handleClose() {
    handleCloseOnBoarding();
    setOpen(false);
  }

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function handleStepChange(activeStepInput) {
    setActiveStep(activeStepInput);
  }

  if (onboardingActive === true) {
    ReactGA.modalview('/onboarding');
  }
  return (
    <Dialog
      fullScreen={fullScreen}
      open={onboardingActive === true && open === true}
      keepMounted
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <div className="layout flex vertical center">
          <Typography variant="h5" gutterBottom align="center">
            Welkom bij de B2B Lead Generator
          </Typography>

          <SwipeableViews
            axis={'x'}
            index={activeStep}
            onChangeIndex={activeStepInput => {
              handleStepChange(activeStepInput);
            }}
            enableMouseEvents
          >
            <div
              className="layout flex vertical center-center"
              style={{ height: '300px' }}
            >
              <SvgLoader
                style={{ height: '200px', width: '100%' }}
                path={'/undraw_address_udes.svg'}
              >
                <SvgProxy
                  selector="#secondary"
                  fill={secondaryBrandColor.main}
                />

                <SvgProxy selector="#primary" fill={'#65617d'} />
              </SvgLoader>
            </div>

            <div
              className="layout flex vertical center-center"
              style={{ height: '300px' }}
            >
              <SvgLoader
                style={{ height: '200px', width: '100%' }}
                path={'/undraw_search_2dfv.svg'}
              >
                <SvgProxy selector="#secondary" fill={'#65617d'} />

                <SvgProxy selector="#primary" fill={secondaryBrandColor.main} />
              </SvgLoader>
            </div>

            <div
              className="layout flex vertical center-center"
              style={{ height: '300px' }}
            >
              <SvgLoader
                style={{ height: '200px', width: '100%' }}
                path={'/undraw_gdpr_3xfb.svg'}
              >
                <SvgProxy selector="#secondary" fill={'#65617d'} />

                <SvgProxy selector="#primary" fill={secondaryBrandColor.main} />
              </SvgLoader>
            </div>

            <div
              className="layout flex vertical center-center"
              style={{ height: '300px' }}
            >
              <SvgLoader
                style={{ height: '200px', width: '100%' }}
                path={'/undraw_alert_mc7b.svg'}
              >
                <SvgProxy
                  selector="#secondary"
                  fill={secondaryBrandColor.main}
                />

                <SvgProxy selector="#primary" fill={'#fff'} />
              </SvgLoader>

              <Button
                href={`mailto:${mail}@lead-generator.app`}
                style={{ marginTop: '20px' }}
                size="small"
                variant="outlined"
                color="primary"
              >
                Mail ons
              </Button>
            </div>

            <div
              className="layout flex vertical center-center"
              style={{ height: '300px' }}
            >
              <SvgLoader
                style={{ height: '200px', width: '100%' }}
                path={'/email.svg'}
              >
                <SvgProxy
                  selector="#secondary"
                  stroke={secondaryBrandColor.main}
                />

                <SvgProxy
                  selector="#primary"
                  stroke={secondaryBrandColor.main}
                />
              </SvgLoader>

              <Button
                href={`mailto:${mail}@lead-generator.app`}
                style={{ marginTop: '20px' }}
                size="small"
                variant="outlined"
                color="primary"
              >
                Mail ons
              </Button>
            </div>
          </SwipeableViews>

          <Typography variant="body2" gutterBottom align="center">
            {tutorialSteps[activeStep].label}
          </Typography>
          <MobileStepper
            steps={maxSteps}
            style={{ width: '100%', background: 'none' }}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={() => {
                  handleNext();
                }}
                disabled={activeStep === maxSteps - 1}
              >
                Volgende
                {<KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={() => {
                  handleBack();
                }}
                disabled={activeStep === 0}
              >
                {<KeyboardArrowLeft />}
                Terug
              </Button>
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          color="primary"
        >
          Sluit venster
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OnboardingScreen;
