import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = preloadedState =>
  createStore(
    rootReducer,
    /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
  );

export default configureStore;
