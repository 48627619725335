import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import React from 'react';

const GeneralFilterSettingWrapper = React.memo(
  function GeneralFilterSettingWrapper({ children }) {
    return (
      <div style={{ padding: '0px 16px 16px 16px' }}>
        <FormControl component="fieldset">
          <FormGroup>{children}</FormGroup>
        </FormControl>
      </div>
    );
  }
);

export default GeneralFilterSettingWrapper;
