import { combineReducers } from 'redux';
import config from '../config/settings';
import * as types from '../constants/ActionTypes';

const resellerTheme = (
  state = {
    isFetching: false,
    didInvalidate: false,
    hasResellerTheme: false,
    primaryBrandColor: config.BASE_THEME_LIGHT_PRIMARY_COLOR,
    secondaryBrandColor: config.BASE_THEME_LIGHT_SECONDARY_COLOR,
    logoUrl: ''
  },
  action
) => {
  switch (action.type) {
    case types.REQUEST_RESELLER_THEME:
      return {
        ...state,
        isFetching: true,
        hasResellerTheme: false,
        didInvalidate: false
      };

    case types.RECEIVE_RESELLER_THEME:
      return {
        ...state,
        isFetching: false,
        hasResellerTheme: true,
        didInvalidate: false,
        ...action.resellerTheme
      };
    case types.INVALIDATE_RESELLER_THEME:
      return {
        ...state,
        isFetching: false,
        hasResellerTheme: false,

        didInvalidate: true
      };

    default:
      return state;
  }
};

const selectedQuery = (state = '', action) => {
  switch (action.type) {
    case types.SELECT_QUERY:
      return action.query;
    default:
      return state;
  }
};

const companies = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: [],
    numFound: 0,
    allowDownload: false
  },
  action
) => {
  switch (action.type) {
    case types.INVALIDATE_QUERY:
      return {
        ...state,
        didInvalidate: true
      };
    case types.REQUEST_COMPANIES:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case types.RECEIVE_COMPANIES:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.companies,
        allowDownload: action.allowDownload,
        numFound: action.numFound,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
};

const companiesByQuery = (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_QUERY:
    case types.RECEIVE_COMPANIES:
    case types.REQUEST_COMPANIES:
      return {
        ...state,
        [action.query + action.settingsMd5]: companies(
          state[action.query + action.settingsMd5],
          action
        )
      };
    default:
      return state;
  }
};

const selectedLocationQuery = (state = '', action) => {
  switch (action.type) {
    case types.SELECT_LOCATION_QUERY:
      return action.query;
    default:
      return state;
  }
};

const location = (
  state = {
    isFetching: false,
    didInvalidate: false,
    items: {}
  },
  action
) => {
  switch (action.type) {
    case types.INVALIDATE_LOCATION_QUERY:
      return {
        ...state,
        didInvalidate: true
      };
    case types.REQUEST_LOCATION:
      return {
        ...state,
        isFetching: true,
        didInvalidate: false
      };
    case types.RECEIVE_LOCATION:
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        items: action.json,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
};

const locationByQuery = (state = {}, action) => {
  switch (action.type) {
    case types.INVALIDATE_LOCATION_QUERY:
    case types.RECEIVE_LOCATION:
    case types.REQUEST_LOCATION:
      return {
        ...state,
        [action.query]: location(state[action.query], action)
      };
    default:
      return state;
  }
};

const settings = (
  state = {
    phone_exists: false,
    email_exists: false,
    winkel_exists: false,
    werkgever_exists: false,
    site_webshop_exists: false,
    filter_location: false,
    orgtype: [],
    sbisectie: [],
    location: [],
    type: config.SETTINGS_DEFAULT_TYPE,
    employees: config.SETTINGS_DEFAULT_EMPLOYEES,
    bag_gebruiksdoel: config.SETTINGS_DEFAULT_BAG_GEBRUIKSDOEL,
    sbi: [],
    site_tech_apps: []
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_SETTINGS:
      return {
        ...state,
        ...action.settings
      };

    case types.SET_SETTINGS:
      return {
        ...action.settings
      };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  resellerTheme,
  companiesByQuery,
  selectedQuery,
  selectedLocationQuery,
  locationByQuery,
  settings
});

export default rootReducer;
