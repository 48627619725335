import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const SelectMultipleChip = React.memo(function SelectMultipleChip({
  value,
  label,
  inputArray,
  handleSettingsChange
}) {
  return (
    <div style={{ padding: '0px 16px 16px 16px' }}>
      <FormControl style={{ width: '100%' }}>
        <InputLabel htmlFor={`select-multiple-chip-${label}`}>
          {label}
        </InputLabel>
        <Select
          multiple
          fullWidth
          value={value}
          onChange={handleSettingsChange}
          input={<Input id={`select-multiple-chip-${label}`} />}
          renderValue={selected => (
            <div className="layout flex horizontal wrap">
              {selected.map(value => (
                <Chip style={{ margin: '2px' }} key={value} label={value} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {inputArray.map(name => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
});

export default SelectMultipleChip;
