import settings from '../config/settings';

const grecaptcha = window.grecaptcha;

async function generateGrecaptchaCode() {
  return new Promise(function(resolve, reject) {
    grecaptcha.ready(function() {
      grecaptcha
        .execute(settings.RECAPTCHA_TOKEN, {
          action: settings.RECAPTCHA_ACTION
        })
        .then(function(token) {
          resolve(token);
        });
    });
  });
}

export default generateGrecaptchaCode;
