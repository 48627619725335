import axios from 'axios';
import config from '../config/settings';
import validateZipcode from '../utilities/validateZipcode';

const access_token = config.MAPBOX_ACCESS_TOKEN;

async function fetchLocation(query) {
  let locationQuery = query;

  if (validateZipcode(query) === true) {
    locationQuery = query.replace(/\D/g, '');
  }

  const response = await axios.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${locationQuery}.json?access_token=${access_token}&types=place,region,postcode&country=NL&language=nl&fuzzyMatch=false`,
    {
      headers: {}
    }
  );
  if (response && response.data) {
    return response.data;
  }
}

export default fetchLocation;
