import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import React from 'react';

const styles = theme => ({
  pinStyle: {
    cursor: 'pointer',
    fill: fade(theme.palette.secondary.main, 0.45),
    stroke: 'none'
  }
});

function Circle({ size = 20, onClick, classes }) {
  return (
    <svg
      height={size}
      viewBox="0 0 2 2"
      className={classes.pinStyle}
      style={{
        transform: `translate(${-size / 2}px,${-size / 2}px)`
      }}
      onClick={onClick}
    >
      <circle cx="1" cy="1" r="1" />
    </svg>
  );
}

export default withStyles(styles, { withTheme: true })(Circle);
