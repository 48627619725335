import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function Suggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem.indexOf(suggestion) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        zIndex: 999,
        fontWeight: isSelected ? 500 : 400
      }}
    >
      <Typography style={{ fontSize: '14px' }} variant="inherit" noWrap>
        {suggestion}
      </Typography>
    </MenuItem>
  );
}
Suggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.array,
  suggestion: PropTypes.string.isRequired
};

export default Suggestion;
