const dx = 5.364418029785156e-6; // degree per horizontal pixel at zoom level 18
const dy = 3.2911570296001447e-6; // degree per vertical pixel at zoom level 18

const mergeBbox = (a, b) => [
  Math.min(a[0], b[0]),
  Math.min(a[1], b[1]),
  Math.max(a[2], b[2]),
  Math.max(a[3], b[3])
];

/**
 * @param bbox The bounding box [w,s,e,n] or an array of bounding boxes
 * @param width The width of the map component
 * @param height The height of the map component
 * @param center (optional) the center point
 * @returns {{width: *, height: *, latitude: *, longitude: *, zoom: number}} MapGL viewport
 */
const calculateViewport = (bbox, width, height, center, radius) => {
  if (radius > 0) {
    const [x, y] = center;
    const dy = (radius * 1000) / (1852 * 60);
    const dx = dy / Math.cos((y * Math.PI) / 180);
    const radiusBbox = [x - dx, y - dy, x + dx, y + dy];
    if (Array.isArray(bbox[0])) {
      bbox.push(radiusBbox);
    } else {
      bbox = [bbox, radiusBbox];
    }
  }
  if (Array.isArray(bbox[0])) {
    bbox = bbox.reduce(mergeBbox);
  }

  let [west, south, east, north] = bbox;
  let longitude = (west + east) / 2;
  let latitude = (north + south) / 2;

  const zoom =
    16.8 -
    Math.max(
      Math.log2((east - west) / dx / width),
      Math.log2((north - south) / dy / height)
    );

  return { width, height, latitude, longitude, zoom };
};

// calculateViewport(
//   [[5, 52, 6, 53],
//    [6, 53, 7, 54]],
//   290, 300, [ 5.5, 52.5])

export default calculateViewport;
