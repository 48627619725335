import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'rc-tooltip/assets/bootstrap.css';
import React from 'react';
import { ReactComponent as Gdpr } from '../undraw_gdpr_3xfb.svg';

function GdprDialog({ aboutDataOpen, handleAboutDataClose }) {
  return (
    <Dialog
      open={aboutDataOpen}
      onClose={handleAboutDataClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Ongewenste (commerciële) benadering van ondernemers
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Veel zelfstandige ondernemers worden geconfronteerd met ongewenst
          gebruik van hun contactgegevens. Vaak is ook het bedrijfsadres gelijk
          aan het woonadres. Om misbruik te voorkomen verstrekken wij{' '}
          <b>geen contactgegevens van freelancers en zzp'ers</b>.
        </DialogContentText>
        <div
          style={{ marginTop: '20px', padding: '20px' }}
          className="layout flex horizontal center-center"
        >
          <Gdpr style={{ height: '200px', width: '400px' }} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAboutDataClose} color="primary" autoFocus>
          Sluit venster
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GdprDialog;
