import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Downshift from 'downshift';
import fuzzaldrin from 'fuzzaldrin-plus';
import keycode from 'keycode';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { updateSettings } from '../actions';
import suggestionsObjectsWrapper from '../data/apps.json';

let suggestionsObjects = suggestionsObjectsWrapper.apps;

let appsObject = suggestionsObjectsWrapper.apps;
let lowerCaseApps = {};
for (var property in appsObject) {
  if (appsObject.hasOwnProperty(property)) {
    let app = appsObject[property];
    app.orginalName = property;
    lowerCaseApps[
      property
        .toLowerCase()
        .split(' ')
        .join('')
    ] = app;
  }
}

let suggestions = [];

for (var prop in suggestionsObjects) {
  // skip loop if the property is from prototype
  if (!suggestionsObjects.hasOwnProperty(prop)) continue;

  suggestions.push(prop);
}

const fuzzyFilter = input => {
  return fuzzaldrin.filter(suggestions, input);
};

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      component="div"
      style={{
        zIndex: 999,
        fontWeight: isSelected ? 500 : 400
      }}
    >
      <Typography style={{ fontSize: '14px' }} variant="inherit" noWrap>
        {suggestion}
      </Typography>
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  return inputLength === 0 ? [] : fuzzyFilter(inputValue);
}

class DownshiftMultiple extends React.Component {
  state = {
    inputValue: '',
    selectedItem: this.props.site_tech_apps
  };

  handleKeyDown = event => {
    const { inputValue } = this.state;
    const { dispatch, settings } = this.props;

    let selectedItem = [...this.props.site_tech_apps];

    if (
      selectedItem.length &&
      !inputValue.length &&
      keycode(event) === 'backspace'
    ) {
      let newSettings = {
        ...settings,
        site_tech_apps: selectedItem.slice(0, selectedItem.length - 1)
      };

      this.setState({
        inputValue: ''
      });

      dispatch(updateSettings(newSettings));
    }
  };

  handleInputChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleChange = item => {
    const { dispatch, settings } = this.props;

    let selectedItem = this.props.site_tech_apps;

    if (this.props.site_tech_apps.indexOf(item) === -1) {
      selectedItem = [...this.props.site_tech_apps, item];
    }

    let newSettings = {
      ...settings,
      site_tech_apps: selectedItem
    };

    this.setState({
      inputValue: ''
    });

    dispatch(updateSettings(newSettings));
  };

  handleDelete = item => () => {
    const { dispatch, settings } = this.props;
    let selectedItem = [...this.props.site_tech_apps];

    selectedItem.splice(selectedItem.indexOf(item), 1);

    let newSettings = {
      ...settings,
      site_tech_apps: selectedItem
    };

    this.setState({
      inputValue: ''
    });

    dispatch(updateSettings(newSettings));
  };

  render() {
    const { classes } = this.props;
    const { inputValue } = this.state;

    let selectedItem = this.props.site_tech_apps;

    return (
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={this.handleChange}
        selectedItem={selectedItem}
      >
        {({
          getInputProps,
          getItemProps,
          isOpen,
          inputValue: inputValue2,
          selectedItem: selectedItem2,
          highlightedIndex
        }) => (
          <div className={classes.container}>
            {renderInput({
              fullWidth: true,
              classes,
              InputProps: getInputProps({
                startAdornment: selectedItem.map(item => (
                  <Chip
                    key={item}
                    tabIndex={-1}
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: '#fff',
                          border: '0.5px solid #e6e6e6'
                        }}
                        src={
                          lowerCaseApps[
                            item
                              .toLowerCase()
                              .split(' ')
                              .join('')
                          ]
                            ? `/icons/${lowerCaseApps[item
    .toLowerCase()
    .split(' ')
    .join('')].icon}`
                            : ''
                        }
                      />
                    }
                    label={
                      lowerCaseApps[
                        item
                          .toLowerCase()
                          .split(' ')
                          .join('')
                      ]
                        ? lowerCaseApps[
                            item
                              .toLowerCase()
                              .split(' ')
                              .join('')
                          ].orginalName
                        : item
                    }
                    className={classes.chip}
                    onDelete={this.handleDelete(item)}
                  />
                )),
                onChange: this.handleInputChange,
                onKeyDown: this.handleKeyDown,
                placeholder: 'Filter op een of meerdere Website Apps'
              }),
              label: 'Website Apps'
            })}
            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue2)
                  .slice(0, 4)
                  .map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem: selectedItem2
                    })
                  )}
              </Paper>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}

DownshiftMultiple.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`
  },
  inputRoot: {
    flexWrap: 'wrap'
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1
  },
  divider: {
    height: theme.spacing(2)
  }
});

function IntegrationDownshift(props) {
  const { classes } = props;

  return (
    <div style={{ padding: '0px 16px 16px 16px' }}>
      <DownshiftMultiple classes={classes} {...props} />
    </div>
  );
}

IntegrationDownshift.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(
  withStyles(styles)(IntegrationDownshift)
);
