import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import React from 'react';

const GeneralFilterSetting = React.memo(function GeneralFilterSetting({
  value,
  valueLabel,
  label,
  handleSettingsChange
}) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          color="secondary"
          onChange={handleSettingsChange}
          value={valueLabel}
        />
      }
      label={label}
    />
  );
});

export default GeneralFilterSetting;
