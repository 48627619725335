import axios from 'axios';
import 'rc-tooltip/assets/bootstrap.css';
import settings from '../config/settings';

let baseUrl = settings.LEAD_GENERATOR_API_BASE_URL;

async function fetchCompanies(selectedQuery, token, settingsBase64) {
  const response = await axios.get(
    `${baseUrl}/dev/lead-generator?q=${
      selectedQuery ? selectedQuery : '*'
    }&grecaptchaToken=${token}&settings=${settingsBase64}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': settings.LEAD_GENERATOR_API_KEY
      }
    }
  );
  if (response && response.data) {
    return response.data;
  }
}

export default fetchCompanies;
